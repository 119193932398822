<template>
    <div style="background:#202020;">
        <div>
            <headerComponent></headerComponent>
        </div>

        <div class="brandinggrid">
           <div class="brandingsidebar "  :class="{ sticky: isSticky }">

               <div class="coltorow" ><div class="sideabtitem" id="tab1" @click="handleTabClick(0)"><h4  :class="{ open: selectedTabIndex===0 }" class="sideabtitemtext introduction" >Introduction</h4></div></div>

               <div class="coltorow" ><div class="sideabtitem" id="tab2" @click="handleTabClick(1)"><h4 class="sideabtitemtext" :class="{ open: selectedTabIndex===1 }">Brand Strategy</h4>
                   <div v-if="selectedTabIndex===1">
                       <a href="#elemntsofbrandstrategy"><h5 class="sideabtsubitem">Elements</h5></a>
                       <a href="#howtobrandstrategy"><h5 class="sideabtsubitem">How to</h5></a>
                   </div>
               </div>
               </div>


               <div class="coltorow" :class="{ open: selectedTabIndex===2 }"><div class="sideabtitem" id="tab3" @click="handleTabClick(2)"><h4 :class="{ open: selectedTabIndex===2 }" class="sideabtitemtext introduction2">Brand Identity</h4>
                   <div v-if="selectedTabIndex===2">
                       <h5 class="sideabtsubitem">Logo</h5>
                       <h5 class="sideabtsubitem">Color</h5>
                       <h5 class="sideabtsubitem">Icons</h5>
                       <h5 class="sideabtsubitem">Illustrations</h5>
                       <h5 class="sideabtsubitem">Typography</h5>
                       <h5 class="sideabtsubitem">Gradient</h5>
                       <h5 class="sideabtsubitem">Buttons</h5>
                       <h5 class="sideabtsubitem">Spacing</h5>
                       <h5 class="sideabtsubitem">Radius</h5>
                   </div>
               </div>
               </div>

           </div>
            <div class="brandingcontent">
                <div v-if="selectedTabIndex===0" >


                    <h2 style="text-align: left">We design for you</h2>
                    <h5 class="designtext">At Monoverse, we assist you in creating a unique Verse for your brand – a space that resonates with your audience, tells a captivating story, and sets you apart in the digital world.
                        <br>
                        <br>
                        As an example, here is the process we went through to build the Monoverse brand. We'll be following the exact same steps to develop the foundations of your own. Because when purchasing a Monoverse domain, you automatically gain access to our branding services.
                    </h5>
                        <div style="margin-top: 32px;" class="introduction">
                            <div class="introimg">

                            </div>
<!--                        <div class="introductionItem1">-->

<!--                        </div>-->
<!--                        <div class="introductionItem2"></div>-->
<!--                        <div class="introductionItem3"></div>-->
<!--                        <div class="introductionItem4"></div>-->
<!--                        <div class="introductionItem5"></div>-->
<!--                        <div class="introductionItem6"></div>-->
<!--                        <div class="introductionItem7"></div>-->
                        <!--                            <img src="@/assets/images/branding/introduction.png">-->
                    </div>


                </div>
                <div class="brandstrategy" v-if="selectedTabIndex===1">
                    <h2 style="text-align: left">Brand Strategy</h2>
                    <div class="brandstrategytext1"> A brand strategy serves as the foundation of your brand. It's an actionable plan that guides your business towards long-term goals and the right audience.
                        <br>
                        <br>
                        We'll help you define your mission, vision, audience, values, and tone of voice to create a strong brand strategy</div>
                    <div class="brandstrategyimage"></div>
                    <div class="brandstrategytext1"> It's basically how people perceive your business. It combines your strategy (brand strategy) to achieve your goals and connect with the right audience, with its visual representation (brand identity) that resonates with your target audience.
                        <br>
                        <br>
                        An effective brand strategy can help you stand out in a competitive market, establish long-term customer loyalty, and drive growth for your business.</div>
                    <div class="brandstrategyelementsHeader" id="elemntsofbrandstrategy">
                        The elements of Brand Strategy
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem1" >
                    <div class="brandstrategyelementstext">

                        <div class="brandstrategyelementstextheader">
                            Purpose
                        </div>
                        <div class="brandstrategyelementstextp">
                            Think about the different aspects of your brand, who you cater to, and what problem your brand aims to solve. Your message should answer the question: why does your brand exist?
                        </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem">
                        <div class="brandstrategyelementstext">

                            <div class="brandstrategyelementstextheader">
                                Brand values
                            </div>
                            <div class="brandstrategyelementstextp">
                                Refer to your value proposition and consider creating a brand positioning statement to serve as a reminder to yourself and your customers of your brand values. Make sure to consistently uphold and communicate those values in your brand messaging.
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem">
                        <div class="brandstrategyelementstext">

                            <div class="brandstrategyelementstextheader">
                                Voice and tone
                            </div>
                            <div class="brandstrategyelementstextp">
                                Your brand’s voice is its overall personality, style, and character, which needs to be consistent. Tone refers to the subtle variations and nuances in your brand’s messaging based on the context, such as marketing to different audiences, or writing an informative blog post with a serious tone versus a social media post using humor.
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem1">
                        <div class="brandstrategyelementstext">

                            <div class="brandstrategyelementstextheader">
                                Design and visual identity
                            </div>
                            <div class="brandstrategyelementstextp">
                                Ensure consistent design across all brand visuals so everything looks cohesive. Use brand colors, typography, and graphics that reflect your purpose and values and resonate with your audience. Make sure your brand name is recognizable and legible.
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem1">
                        <div class="brandstrategyelementstext">

                            <div class="brandstrategyelementstextheader">
                                Brand story
                            </div>
                            <div class="brandstrategyelementstextp">
                                Your brand’s story should express what makes it unlike any other. When crafting your brand story, consider your brand’s experience and brand equity — that is to say, how customers perceive your brand. Engage customers through storytelling to establish an emotional connection and differentiate your brand from your competitors.
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementsHeader" id="howtobrandstrategy">
                        How to build a Brand Strategy
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem2" >
                        <div class="brandstrategyelementstext2">
                            <div class="brandstrategyelementsicon">
                                <img src="./../assets/images/branding/arrowdiagonal.png">
                            </div>
                            <div class="brandstrategyelementstextp2">
                                Identify your target audience.
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem2" >
                        <div class="brandstrategyelementstext2">
                            <div class="brandstrategyelementsicon">
                                <img src="./../assets/images/branding/arrowdiagonal.png">
                            </div>
                            <div class="brandstrategyelementstextp2">
                                Establish a unique position in the market
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem2" >
                        <div class="brandstrategyelementstext2">
                            <div class="brandstrategyelementsicon">
                                <img src="./../assets/images/branding/arrowdiagonal.png">
                            </div>
                            <div class="brandstrategyelementstextp2">
                                Craft a compelling message
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem2" >
                        <div class="brandstrategyelementstext2">
                            <div class="brandstrategyelementsicon">
                                <img src="./../assets/images/branding/arrowdiagonal.png">
                            </div>
                            <div class="brandstrategyelementstextp2">
                                Develop an engaging visual identity
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem2" >
                        <div class="brandstrategyelementstext2">
                            <div class="brandstrategyelementsicon">
                                <img src="./../assets/images/branding/arrowdiagonal.png">
                            </div>
                            <div class="brandstrategyelementstextp2">
                                Use technology to reach your audience
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem2" >
                        <div class="brandstrategyelementstext2">
                            <div class="brandstrategyelementsicon">
                                <img src="./../assets/images/branding/arrowdiagonal.png">
                            </div>
                            <div class="brandstrategyelementstextp2">
                                Analyze and refine your strategy regularly
                            </div>
                        </div>
                    </div>
                    <div class="brandstrategyelementitem brandstrategyelementitem3" >
                    </div>


                </div>

                <div v-if="selectedTabIndex===2" >
                    <h2 style="text-align: left">Brand Identity</h2>
                    <h5 class="designtext">
                        This is a work sample of how we are going to shape your brand’s identity, vision and purpose. We use our brand Monoverse, as an example.
                        <br>
                        <br>
                        This is the basic level that we offer for starting your business. For an extended version please contact us and let’s discuss it further.
                    </h5>
                    <v-row gutter="32" style="margin:auto; margin-top: 64px;">
                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingLogo"></div>
                            <h5 style="text-align: left">Logo</h5>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingColour"></div>
                            <h5 style="text-align: left">Color</h5>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingIcons"></div>
                            <h5 style="text-align: left">Icons</h5>
                        </v-col>


                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingIllustrations"></div>
                            <h5 style="text-align: left">Illustrations</h5>

                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingTypography"></div>
                            <h5 style="text-align: left">Typography</h5>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingGradient"></div>
                            <h5 style="text-align: left">Gradient</h5>
                        </v-col>


                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingButtons"></div>
                            <h5 style="text-align: left">Buttons</h5>

                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingSpacing"></div>
                            <h5 style="text-align: left">Spacing</h5>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <div class="brandingRadius"></div>
                            <h5 style="text-align: left">Radius</h5>
                        </v-col>
                    </v-row>

                </div>
            </div>
        </div>




        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";

    export default {
        name: "Branding",
        components: {headerComponent,mainfooter},
        data: () => ({
            isSticky: false,
            stickyOffset: 50,
            panel: 1,
            show:false,
            tabs:[
                {id: 1,description : 'Introduction'},
                {id: 2, description : 'Brand Strategy'},
                {id: 3, description : 'Brand Identity'}],
            selectedTabIndex:0,
            isOpen:true,
        }),
        methods:{
            handleTabClick(index){
                this.selectedTabIndex = index;

                console.log(index)

            },
            handleScroll() {
                this.isSticky = window.scrollY >= this.stickyOffset;
            }

        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            this.show = true; // might need this.$nextTick
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },

    }
</script>

<style>

    .v-btn{
        width: 100%;
    }

    #tab1 {
        /*background-color: black!important;*/
        cursor: pointer;
    }
    #tab2{
        /*background-color: black!important;*/
        cursor: pointer;
    }
    #tab3 {
        /*background-color: black!important;*/
        cursor: pointer;
    }



</style>


